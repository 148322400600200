import React from 'react';
import {Layout} from '../components/layout/layout';
import {BuildTeam} from '../pages-components/build-team/build-team';

const BuildTeamPage = () => (
  <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
    <Layout>
      <BuildTeam />
    </Layout>
  </div>
);

export default BuildTeamPage;
