import {useStaticQuery, graphql} from 'gatsby';
import React from 'react';
import {mark} from '../../utils/marked';
import st from './build-team.module.scss';
import {BuildTeamForm} from './form/build-team-form';
import {Helmet} from 'react-helmet';
import headerImg from './assets/header.svg';
export const BuildTeam = () => {
  const {
    restApiBuildTeamFormPage: {seo, title, subtitle, questions, form_subtitle},
  } = query();

  return (
    <>
      <Helmet meta={[{name: 'description', content: seo.description}]}>
        <title>{seo.title}</title>
      </Helmet>
      <div className={st.wrapper__head}>
        <div className={st.section}>
          <div className={st.text__wrapper}>
            <div className={st.header__text}>
              <h1 className={st.title} dangerouslySetInnerHTML={mark(title)}></h1>
              <div className={st.subtitle} dangerouslySetInnerHTML={mark(subtitle)}></div>
            </div>
            <div className={st.header__container}>
              <img src={headerImg} alt='brands' className={st.header__image} />
            </div>
          </div>
        </div>
      </div>
      <div className={st.wrapper__body}>
        <div className={st.section}>
          <div className={st.form__wrapper}>
            <div className={st.header__form}>
              <div className={st.title__container__form}>
                <h2 className={st.title__form}>Boost your project <br/> <span className={st.title__form__bold}>development!</span></h2>
              </div>
              <div>
                <div className={st.subtitle__form}>Thanks for your interest in cooperating with Blue House!</div>
                <div className={st.desc__form}>
                  Before we get started, we'd like to ask a few questions to better understand your business needs.
                </div>
              </div>
            </div>
            <BuildTeamForm questions={questions} />
          </div>
        </div>
      </div>
    </>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiBuildTeamFormPage {
        seo {
          title
          description
        }
        title
        subtitle
        questions {
          previous_answer
          q1
          title
          q4
          q3
          q2
          multichoice
        }
        main_brand {
          url
        }
        form_title
        form_subtitle
        brands {
          url
        }
      }
    }
  `);
