import React, {useState} from 'react';
import {buildTeamFormId, cookiesPolicyUrl, portalId, privacyPolicyUrl} from '../../../api';
import {Button} from '../../../components/button/button';
import {isBrowser} from '../../../utils/browser';
import {mark} from '../../../utils/marked';
import {setMissingClass} from '../../blog/subscribe/subscribe';
import {ErrorTypes, handleInput} from '../../join-us/form/form';
import st from './build-team-form.module.scss';

export const BuildTeamForm = ({questions}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [step, setStep] = useState(1);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phone, setPhone] = useState('');
  const [firstAnswer, setFirstAnswer] = useState('');
  const [secondAnswer, setSecondAnswer] = useState('');
  const [consent, setConsent] = useState(false);
  const [missingInput, setMissingInput] = useState([]);

  const [currentQuestion, setCurrentQuestion] = useState(
    questions.find(question => !question.previous_answer, undefined, {}),
  );

  const clearFields = () => {
    setFirstName('');
    setEmail('');
    setCompanyName('');
    setLastName('');
    setPhone('');
    setFirstAnswer('');
    setSecondAnswer('');
  };

  const handleFormButton = e => {
    if (e) e.preventDefault();
    if ((step === 1 && !firstAnswer) || (step === 2 && !secondAnswer)) {
      return;
    }
    if (step === 3) {
      const missingFields = [];

      if (!firstName) {
        missingFields.push('firstName');
      }
      if (!email) {
        missingFields.push('email');
      }
      if (!lastName) {
        missingFields.push('lastName');
      }
      if (!consent) {
        missingFields.push('consent');
      }
      if (missingFields.length > 0) {
        setMissingInput(missingFields);
        return;
      }

      const isBrowser = typeof window !== 'undefined';
      const pageUri = isBrowser ? window.location.href : null;
      const pageName = isBrowser ? document.title : null;
      const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${buildTeamFormId}`;
      setLoading(true);
      setMissingInput([]);
      setError({});

      const body = {
        submittedAt: Date.now(),
        fields: [
          {
            name: 'email',
            value: email,
          },
          {
            name: 'firstname',
            value: firstName,
          },
          {
            name: 'lastname',
            value: lastName,
          },
          {
            name: 'company',
            value: companyName,
          },
          {
            name: 'phone',
            value: phone,
          },
          {name: 'privacy_policy_consent', value: consent},
          {
            name: 'service_type',
            value: firstAnswer,
          },
          {
            name: 'other_information',
            value: secondAnswer,
          },
        ],
        context: {
          pageUri,
          pageName,
        },
        legalConsentOptions: {
          consent: {
            consentToProcess: true,
            text: `I acknowledge that the Blue House Technologies screening process is confdential and that I will not publicly disclose details about this process. By submitting, you acknowledge that you have read and agree to our Privacy Policy, and Cookie Policy`,
          },
        },
      };

      const payload = JSON.stringify(body);
      const xhr = new XMLHttpRequest();

      xhr.open('POST', postUrl);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          clearFields();
          setStep(4);
          setFirstQuestion();
          setLoading(false);
        } else if (xhr.readyState == 4 && [400, 403, 404].includes(xhr.status)) {
          const newError = {};
          let isGlobal = true;
          const response = JSON.parse(xhr.response);
          if (response.errors?.find(error => error.errorType === ErrorTypes.Email)) {
            newError['email'] = 'Invalid email address.';
            isGlobal = false;
          }
          if (response.errors?.find(error => error.errorType === ErrorTypes.Phone)) {
            newError['phone'] = 'Invalid phone number.';
            isGlobal = false;
          }
          if (isGlobal) {
            newError['form'] = 'We had some issues with submission. Try again later.';
          }
          setError(newError);
          setLoading(false);
        }
      };

      //Set data for analitics
      if (isBrowser && companyName) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'contactformCompletion',
          companyName: companyName,
        });
      }
      xhr.send(payload);
    }
    if (step < 3) {
      setStep(step + 1);
      setCurrentQuestion(questions.find(question => question.previous_answer === firstAnswer));

      //Set data for analitics
      if (step === 1 && isBrowser) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'contactform1step',
          goal: firstAnswer,
        });
      }

      if (step === 2 && isBrowser) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'contactform2step',
          role: secondAnswer,
        });
      }
    }
  };
  const setFirstQuestion = () => {
    setCurrentQuestion(questions.find(question => !question.previous_answer, undefined, {}));
  };
  const isActive = answer => {
    if (step === 1) {
      return answer === firstAnswer;
    } else if (step === 2) {
      return answer === secondAnswer;
    }
  };

  const answerQuestion = answer => () => {
    if (step === 1) {
      setFirstAnswer(answer);
    } else if (step === 2) {
      setSecondAnswer(answer);
    }
  };

  const handlePrevButton = e => {
    e.preventDefault();
    if (step === 2) {
      setFirstQuestion();
      setSecondAnswer('');
      setStep(1);
    } else if (step === 3) {
      setStep(2);
    }
  };

  return (
    <div className={st.form__wrapper}>
      {step !== 4 && <div className={st.step}>Step {step} of 3</div>}
      <div className={st.question}>
        {step === 1 && currentQuestion?.title}
        {step === 2 && 'Please give us more details below'}
        <div className={st.question__summary}>
          {step === 3 && 'After submitting the final details, we will come back to you.'}
        </div>
      </div>
      {currentQuestion.multichoice && step === 1 ? <div className={st.annotation}>(multiple choice)</div> : null}
      <form className={st.form}>
        {step === 1 && (
          <Answers answerQuestion={answerQuestion} currentQuestion={currentQuestion} isActive={isActive} />
        )}
        {step === 2 && (
          <div className={st.single__input__wrapper}>
            <div className={st.input__style}>
              <input
                placeholder={'Write Your Answer here'}
                value={secondAnswer}
                onChange={handleInput(setSecondAnswer)}
              />
            </div>
          </div>
        )}
        {step === 3 && (
          <div className={st.form__container}>
            <div className={st.form__column}>
              <div className={st.input__wrapper}>
                <div className={st.input__style}>
                  <input
                    className={`${setMissingClass(missingInput, 'email', st.required)} ${error?.email && st.required}`}
                    placeholder={'Email address'}
                    value={email}
                    onChange={handleInput(setEmail)}
                    type={'email'}
                  />
                </div>
              </div>
              <div className={st.input__wrapper}>
                <div className={st.input__style}>
                  <input
                    className={setMissingClass(missingInput, 'firstName', st.required)}
                    placeholder={'First name'}
                    value={firstName}
                    onChange={handleInput(setFirstName)}
                  />
                </div>
              </div>
              <div className={st.input__wrapper}>
                <div className={st.input__style}>
                  <input
                    className={setMissingClass(missingInput, 'lastName', st.required)}
                    placeholder={'Last name'}
                    value={lastName}
                    onChange={handleInput(setLastName)}
                  />
                </div>
              </div>
            </div>
            <div className={st.form__column}>
              <div className={st.input__wrapper}>
                <div className={st.input__style}>
                  <input
                    className={`${error?.phone && st.required}`}
                    placeholder={'Phone'}
                    value={phone}
                    onChange={handleInput(setPhone)}
                  />
                </div>
              </div>
              <div className={st.input__wrapper}>
                <div className={st.input__style}>
                  <input placeholder={'Company name'} value={companyName} onChange={handleInput(setCompanyName)} />
                </div>
              </div>
              <div className={`${st.input__wrapper} ${st.checkbox__container}`}>
                <input id='consent' type='checkbox' onClick={() => setConsent(!consent)} checked={consent} />
                <div className={`${st.checkmark} ${setMissingClass(missingInput, 'consent', st.required)}`}></div>
                <label htmlFor='consent' className={st.policy}>
                  By completing this form, you acknowledge that you have read and agree to Blue House Technologies{' '}
                  <a className={st.link} href={privacyPolicyUrl} rel='noreferrer' target='_blank'>
                    Privacy Policy
                  </a>
                  , and{' '}
                  <a className={st.link} href={cookiesPolicyUrl} rel='noreferrer' target='_blank'>
                    Cookie Policy
                  </a>
                </label>
              </div>
            </div>
          </div>
        )}
        {step === 4 && (
          <div className={st.thanks}>
            <span className={st.bold}>Thank You</span> for submission,
            <br /> We’ ll respond up to 24 hours
          </div>
        )}
        {step !== 4 && (
          <div className={`${st.buttons} ${step === 3 ? st.buttons__left : ''}`}>
            {step > 1 ? (
              <Button stClassName={`${st.button__prev}`} onClick={handlePrevButton} disabled={loading}>
                Previous
              </Button>
            ) : null}

            <Button disabled={loading} stClassName={`${st.button}`} onClick={handleFormButton}>
              {step === 3 ? (loading ? 'Sending' : 'Submit') : 'Next'}
            </Button>
          </div>
        )}
        {step === 3 ? (
          <>
            {error.email && <span className={st.error}>{error.email}</span>}
            {error.phone && <span className={st.error}>{error.phone}</span>}
            {error.form && <span className={st.error}>{error.form}</span>}
          </>
        ) : null}
      </form>
    </div>
  );
};

const Answers = ({answerQuestion, currentQuestion, isActive}) => (
  <div className={`${st.answers} ${currentQuestion.multichoice ? st.answers__checkmark : ''}`}>
    <div
      onClick={answerQuestion(currentQuestion?.q1)}
      className={`${st.answer} ${isActive(currentQuestion?.q1) ? st.active : ''}`}
    >
      <div dangerouslySetInnerHTML={mark(currentQuestion?.q1)}></div>
    </div>
    <div
      onClick={answerQuestion(currentQuestion?.q2)}
      className={`${st.answer} ${isActive(currentQuestion?.q2) ? st.active : ''}`}
    >
      <div dangerouslySetInnerHTML={mark(currentQuestion?.q2)}></div>
    </div>
    <div
      onClick={answerQuestion(currentQuestion?.q3)}
      className={`${st.answer} ${isActive(currentQuestion?.q3) ? st.active : ''}`}
    >
      <div dangerouslySetInnerHTML={mark(currentQuestion?.q3)}></div>
    </div>
    <div
      onClick={answerQuestion(currentQuestion?.q4)}
      className={`${st.answer} ${isActive(currentQuestion?.q4) ? st.active : ''}`}
    >
      <div dangerouslySetInnerHTML={mark(currentQuestion?.q4)}></div>
    </div>
  </div>
);
